<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <acountNav></acountNav>
                <div class="acount-box">
                    <div class="acount-header">
                        <div class="acount-header-item">常见问题</div>
                    </div>
                    <div class="acount-main">
                        <div class="search">
                            <img src="../../static/icon/search-icon.png">
                            <input type="text" placeholder="搜索">
                        </div>
                        <div class="problem-lists">
                            <div class="problem-item" v-for="(item , index) in problemLists" :key="index">
                                <div class="problem-item-q">
                                    <img src="../../static/icon/q-icon.png">
                                    <span>如何注册手机号？</span>
                                </div>
                                <div class="problem-item-a">
                                    <img src="../../static/icon/a-icon.png">
                                    <span>注册手机号码的方法取决于您选择的手机运营商和服务平台。以下是注册手机号码的一般步骤：
选择手机运营商。在中国，主要的手机运营商包括中国移动、中国联通和中国电信。您可以根据自己的需求
和服务覆盖范围选择合适的运营商。1234办理手机号码注册手续。访问所选运营商的官方网站或前往营业厅注册
账号。注册时需要提供有效身份证件，如身份证或护照。12345提交身份信息和选择套餐。注册账号后，您需要
提供一些必要的身份信息，如姓名、身份证号码等，并根据您的通信需求选择合适的套餐。缴纳费用。完成套餐选
择和身份验证后，您需要支付相应的费用。</span>
                                </div>
                            </div>
                            <noData v-if="problemLists.length === 0" title="暂无数据"></noData>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import noData from '@/components/noData.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import acountNav from '@/components/acountNav.vue'
export default {
    name: "problem",
    components: {
        pageHeader,
        pageFooter,
        acountNav,
        noData
    },
    data() {
        return {
            problemLists:[]
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.acount-box {
    width: 888px;
    background-color: #ffffff;
    min-height: calc(100vh - 120px - 16px - 28px);
    margin-top: 16px;
    margin-bottom: 28px;
}

.acount-header {
    width: 888px;
    border-bottom: 1px solid #E8E8E8;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
}

.acount-header-item {
    height: 60px;
    margin-right: 70px;
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.acount-header-item-active {
    position: relative;
    color: #DD0722;
}

.acount-header-item-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #DD0722;
}

.acount-main {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}
.search{
    width: 848px;
    background-color: #F5F6F7;
    border-radius: 16px;
    height: 38px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 16px;
}
.search img{
    width: 18px;
    height: 18px;
}
.search input{
    flex: 1;
    background-color: #F5F6F7;
    border: 0;
    padding-left: 10px;
    color: #8C9198;
    font-size: 14px;
    height: 38px;
}
.problem-lists{
    width: 848px;
    display: flex;
    flex-direction: column;
}
.problem-item{
    border-bottom: 1px solid #CDD2D9;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
}
.problem-item-q{
    display: flex;
    align-items: flex-start;
}
.problem-item-q span{
    font-weight: bold;
    color: #333333;
    font-size: 16px;
}

.problem-item-a{
    display: flex;
    align-items: flex-start;
    margin-top: 14px;
}
.problem-item-a span{
    line-height: 28px;
    color: #333333;
    font-size: 16px;
}
.problem-item-q img,.problem-item-a img{
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
</style>
